import { SortType, TableHeadCol } from "~/types/general";

export const useGetTableSorting = (tableCols: TableHeadCol[]) => {
  const activeSorting = tableCols.find(
    (c) => c.sortable && c.type && c.type !== SortType.NO_SORT
  );

  return (
    activeSorting && {
      [activeSorting?.param ||
      "sort[]"]: `${activeSorting.field}-${activeSorting.type}`,
    }
  );
};
